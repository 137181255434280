import createAuth0Client from "@auth0/auth0-spa-js";
import { ref } from 'vue';

const DEFAULT_REDIRECT_CALLBACK = () =>
  window.history.replaceState({}, document.title, window.location.pathname);

let instance;

export const getInstance = () => instance;

export const useAuth0 = ({
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  redirectUri = window.location.origin,
  ...options
}) => {
  if (instance) return instance;

  instance = {
    loading: ref(true),
    isAuthenticated: false,
    user: {},
    auth0Client: null,
    popupOpen: false,
    error: null,
    async loginWithPopup(options, config) {
      this.popupOpen = true;

      try {
        await this.auth0Client.loginWithPopup(options, config);
        this.user = await this.auth0Client.getUser();
        this.isAuthenticated = await this.auth0Client.isAuthenticated();
        this.error = null;
      } catch (e) {
        console.error(e);
        this.error = e;
      } finally {
        this.popupOpen = false;
      }
    },
    async handleRedirectCallback() {
      this.loading.value = true;
      try {
        await this.auth0Client.handleRedirectCallback();
        this.user = await this.auth0Client.getUser();
        this.isAuthenticated = true;
        this.error = null;
      } catch (e) {
        this.error = e;
      } finally {
        this.loading.value = false;
      }
    },
    loginWithRedirect(o) {
      return this.auth0Client.loginWithRedirect(o);
    },
    getIdTokenClaims(o) {
      return this.auth0Client.getIdTokenClaims(o);
    },
    getTokenSilently(o) {
      return this.auth0Client.getTokenSilently(o);
    },
    getTokenWithPopup(o) {
      return this.auth0Client.getTokenWithPopup(o);
    },
    getUserClientId() {
      return this.user[process.env.VUE_APP_AUTH0_METADATA_KEY].espaceClient.clientId;
    },
    getUserDossierId() {
      return this.user[process.env.VUE_APP_AUTH0_METADATA_KEY].espaceClient.dossierId;
    },
    getUserDossierIdWithPrefix() {
      return 'DCF' + ('00000000' + this.user[process.env.VUE_APP_AUTH0_METADATA_KEY].espaceClient.dossierId).slice(-8);
    },
    getUserEmail() {
      return this.user.email ?? '';
    },
    logout(o) {
        if (o != undefined && (o.returnTo == undefined || o.returnTo == '')) {
            o.returnTo = window.location.origin
        } else if (o == undefined) {
            o = { returnTo: window.location.origin }
        }

      return this.auth0Client.logout(o);
    },
    async created() {
      this.auth0Client = await createAuth0Client({
        ...options,
        client_id: options.clientId,
        redirect_uri: redirectUri
      });

      try {
        if (
          window.location.search.includes("code=") &&
                    window.location.search.includes("state=")
        ) {
          const { appState } = await this.auth0Client.handleRedirectCallback();

          this.error = null;
          onRedirectCallback(appState);
        }
      } catch (e) {
        this.error = e;
      } finally {
        this.isAuthenticated = await this.auth0Client.isAuthenticated();
        this.user = await this.auth0Client.getUser();

        this.loading.value = false;
      }
    },
    getChangePasswordUrl() {
      return `${this.auth0Client.domainUrl}/dbconnections/change_password`;
    },
    getConnectionName() {
      return process.env.VUE_APP_AUTH0_CONNECTION_NAME;
    }
  };

  return instance;
};

export const Auth0Plugin = {
  install(app, options) {
    app.config.globalProperties.auth = useAuth0(options);

    app.config.globalProperties.auth.created();
  }
};
